import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl,request_sign} from "@/utils/const";
import { encryptCode,decryptCode } from "@/utils/function";
import API from '@/api/manager/user/userInfo';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import { request } from "@/request/http";
export default {
    name: "expDetail",
    components: {
        VueUeditorWrap
    },
    data() {
        return{
            list:[],
            userid1:"",
            userid2:"",
        }
    },
    created(){
        let self = this;
        self.getData();
    },
    methods:{
        getData()
        {
            let self = this;
            // 请求参数
            let data = {};
            nprogressStart();
            self.$Loading.show();
            request("POST", `/index.php/adminUser/getUserRepeatList`, data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if(result.code > 0 )
                    {
                        self.list = result.data;
                        console.log(self.list)
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })   
        },

        reset(index)
        {
            let self = this;
            self.$confirm('确定要交换重置吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let obj = self.list[index];
                self.userid1 = obj[0]?.user_id;
                self.userid2 = obj[1]?.user_id;
                // 请求参数
                let data = {
                    a: self.userid1,
                    b: self.userid2,
                };
                nprogressStart();
                self.$Loading.show();
                request("POST", `/index.php/adminUser/JiaoHuanChongZhi`, data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        self.getData();
                        if(result.code > 0 )
                        {
                            self.getData();
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    }) 
            }).catch(() => {
                // Message.info('已取消');        
            });  
        },

        jhjinyong(index)
        {
            let self = this;
            // 删除请求
            self.$confirm('确定要交换禁用吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let obj = self.list[index];
                self.userid1 = obj[0]?.user_id;
                self.userid2 = obj[1]?.user_id;
                // 请求参数
                let data = {
                    a: self.userid1,
                    b: self.userid2,
                };
                nprogressStart();
                self.$Loading.show();
                request("POST", `/index.php/adminUser/JiaoHuanJinYong`, data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        self.getData();
                        if(result.code > 0 )
                        {
                            self.getData();
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    }) 
            }).catch(() => {
                // Message.info('已取消');        
            });  
        },

        jingyonga(index)
        {
            let self = this;
            // 删除请求
            self.$confirm('确定要禁用A吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let obj = self.list[index];
                self.userid1 = obj[0]?.user_id;
                self.userid2 = obj[1]?.user_id;
                // 请求参数
                let data = {
                    a: self.userid1,
                    b: self.userid2,
                };
                nprogressStart();
                self.$Loading.show();
                request("POST", `/index.php/adminUser/jinYongShenSuA`, data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if(result.code > 0 )
                        {
                            self.getData();
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    }) 
            }).catch(() => {
                // Message.info('已取消');        
            });  
        },

        hulue: function (index) 
        {
            let self = this;
            // 删除请求
            self.$confirm('确定要忽略A吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let obj = self.list[index];
                self.userid1 = obj[0]?.user_id;
                self.userid2 = obj[1]?.user_id;
                // 请求参数
                let data = {
                    a: self.userid1,
                    b: self.userid2,
                };
                nprogressStart();
                self.$Loading.show();
                request("POST", `/index.php/adminUser/HulueSamePersonal`, data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if(result.code > 0 )
                        {
                            self.getData();
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    }) 
            }).catch(() => {
                // Message.info('已取消');        
            });  
        },

    }
}
<template>
  <div class="userInfo">
    <div v-if="list.length > 0">
      <el-card v-for="(item,index) in list"  :value="index" style="margin-bottom:15px;">
        <div style="margin-bottom:10px;float:right;">
          <el-button type="primary" size="small" @click="reset(index)">交换重置</el-button>
          <el-button type="primary" size="small" @click="jhjinyong(index)">交换禁用</el-button>
          <el-button type="primary" size="small" @click="jingyonga(index)" >禁用A</el-button>
          <el-button type="primary" size="small" @click="hulue(index)">忽略</el-button>
        </div>
        <el-table
          :data="item"
          stripe
          style="width: 100%">
          <el-table-column
            prop="role"
            label="序号"
            width="120">
          </el-table-column>
          <el-table-column
            prop="name"
            label="用户名"
            width="180">
          </el-table-column>
          <el-table-column
            prop="tel"
            label="手机号">
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="学校">
          </el-table-column>
          <el-table-column
            prop="time"
            label="提交时间">
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div v-else style="width: 100%;text-align: center;font-size: 22px;padding-top: 200px;">
      暂无数据
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/css/manager/user/userRepeat.scss";
</style>
<script>
  import userInfo from "@/assets/js/manager/user/userRepeat.js";
  export default 
  {
    ...userInfo,
  };
</script>